import React, { useEffect, useState, useRef } from "react";
import "./mydown.scss";
import axios from "axios";
import { Card } from "primereact/card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const MyDown = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState([]);
  const actemail = localStorage.getItem("p2pemailxemail");
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdownline",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userdata);
      }
    };
    fetchData();
  }, [actemail, url, apikey]);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="mydown">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="My Downline">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Member Id</TableCell>
                    <TableCell align="center">Whats App</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">{row.whatsapp}</TableCell>
                      <TableCell align="center">
                        {row.is_active === 0 ? "Not Active" : "Active"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
