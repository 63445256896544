import React, { useEffect, useState } from "react";
import "./helpstatus.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
export const HelpStatus = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getpendinglist", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.pendinglist);
      }
    };
    fetchData();
  }, [apikey, url]);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="helpstatus">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Pending Help Request">
          <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Mem Id</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Level</TableCell>
                    <TableCell align="center">Id</TableCell>
                    <TableCell align="center">Whats App</TableCell>
                    <TableCell align="center">BEP20</TableCell>
                    <TableCell align="center">Approve</TableCell>
                    <TableCell align="center">Reject</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.level}</TableCell>
                      <TableCell align="center">{row.fid}</TableCell>
                      <TableCell align="center">{row.whatsapp}</TableCell>
                      <TableCell align="center">{row.bep20}</TableCell>
                      <TableCell align="center"><Button label="Approve" /></TableCell>
                      <TableCell align="center"><Button label="Reject" /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
