import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { Card } from "primereact/card";
import "./userlist.scss";
import { Button } from "primereact/button";
import {Link} from "react-router-dom"
export const UserList = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getusers", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.users);
      }
    };
    fetchData();
  }, [apikey, url]);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="userlist">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Users">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Mem Id</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Pass</TableCell>
                    <TableCell align="center">Is Active</TableCell>
                    <TableCell align="center">Active Level</TableCell>
                    <TableCell align="center">Down</TableCell>
                    <TableCell align="center">Active Down</TableCell>
                    <TableCell align="center">Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.password}</TableCell>
                      <TableCell align="center">{row.is_active}</TableCell>
                      <TableCell align="center">{row.act_level}</TableCell>
                      <TableCell align="center">{row.mydown}</TableCell>
                      <TableCell align="center">{row.actdown}</TableCell>
                      <TableCell align="center"><Link to={`/admin/edituser/${row.memberid}`}> <Button label="Edit" /></Link></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
