import React, { useEffect, useState, useRef } from "react";
import "./profile.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
export const Profile = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("p2pemailxemail");
  const actuser = localStorage.getItem("p2pemailxemail");
  const [data, setData] = useState({});
  const [otpbuttonstate, setOtpButtonstate] = useState(0);
  const [otpsubmit, setOtpSubmit] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(-1);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdetails",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userdata);
        formik.setFieldValue("whatsapp",resp.data.userdata.whatsapp)
        formik.setFieldValue("bep20",resp.data.userdata.bep20)
        setButtonDisabled(0);
      } else if (resp.status === 203) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.message,
        });
      }
    };
    fetchData();
  }, [actemail, url, apikey]);

  const validationSchema = Yup.object({
    email: Yup.mixed().required("Its Required!!!"),
    whatsapp: Yup.mixed().required("Its Required!!!"),
    bep20: Yup.mixed().required("Its Required!!!"),
    otp: Yup.mixed().required("Its Required!!!"),
  });
  const formik = useFormik({
    initialValues: {
      email: actemail,
      otp:"",
      whatsapp: "",
      bep20: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/profileupdate",
        {
          values
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(0);
      }
    },
  });
  const sendotp = async () => {
    setOtpButtonstate(1);
    const resp = await axios.post(
      url + "/user/otpprofile",
      { actuser },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      setOtpButtonstate(2);
    } else {
      setOtpButtonstate(0);
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="profile">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6 left">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <img src="/images/tlogo.png" width="100" alt="" />
                  <h3>MY PROFILE</h3>
                  <div className="component cust-bg">
                    <div>
                      <div>Email Id</div>
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email"
                        readOnly
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      {otpbuttonstate === 0 ? (
                        <Button
                          type="button"
                          severity="help"
                          onClick={() => sendotp()}
                          label="Get OTP"
                        />
                      ) : (
                        <>
                          {otpbuttonstate === 1 ? (
                            <Button
                              type="button"
                              severity="help"
                              label="Fetching OTP..."
                            />
                          ) : (
                            <Button
                              type="button"
                              severity="help"
                              label="OTP Sent to Mail."
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <div>OTP</div>
                      <InputText
                        type="text"
                        name="otp"
                        placeholder="OTP"
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                      />
                      {getFormErrorMessage("otp")}
                    </div>
                    <div>
                      <div>WHATSAPP</div>
                      <InputText
                        type="text"
                        name="whatsapp"
                        placeholder="Whats App"
                        onChange={formik.handleChange}
                        value={formik.values.whatsapp}
                      />
                      {getFormErrorMessage("whatsapp")}
                    </div>
                    
                    <div>
                      <div>BEP20</div>
                      <InputText
                        type="text"
                        name="bep20"
                        placeholder="BEP20"
                        onChange={formik.handleChange}
                        value={formik.values.bep20}
                      />
                      {getFormErrorMessage("bep20")}
                    </div>
                    {isButtonDisabled === -1 ? (
                      <Button
                        type="button"
                        label="Not Allowed"
                        disabled
                        size="small"
                      />
                    ) : (
                      <>
                        {isButtonDisabled === 0 ? (
                          <Button type="submit" label="Update" size="small" />
                        ) : (
                          <>
                            {isButtonDisabled === 1 ? (
                              <Button
                                type="button"
                                label="Confirming..."
                                size="small"
                              />
                            ) : (
                              <Button
                                type="button"
                                label="Confirmed"
                                size="small"
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
      </div>
    </div>
  );
};
