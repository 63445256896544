import React, { useEffect, useState, useRef } from "react";
import "./transferpin.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
export const TransferPin = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("p2pemailxemail");
  const actid = localStorage.getItem("p2pemailxid");
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [pin, setPin] = useState("0");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getpin",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setPin(resp.data.balance);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    transto: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number().min(1, "Invalid Amount").required("Invalid Password."),
  });
  const formik = useFormik({
    initialValues: {
      email: actemail,
      memberid: actid,
      transto: "",
      amount: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.amount * 1 > pin) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Amount. Try correcting data.",
        });
        return false;
      }
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/pintransfer",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Pin Transfered successfull.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong! Try Correcting again.",
        });
        setButtonDisabled(0);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="transferpin">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-6 left">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/tlogo.png" width="100" alt="" />
                    <h3>PIN TRANSFER</h3>
                    <div className="component">
                      <div>Available Pin</div>
                      <InputText
                        type="text"
                        name="mypin"
                        placeholder="Email Address"
                        readOnly
                        onChange={formik.handleChange}
                        value={pin}
                      />
                      {getFormErrorMessage("mypin")}
                      <Divider />
                      <div>Transfer To</div>
                      <InputText
                        type="email"
                        name="transto"
                        placeholder="Email Address"
                        onChange={formik.handleChange}
                        value={formik.values.transto}
                      />
                      {getFormErrorMessage("transto")}
                      <div>Amount</div>
                      <InputText
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      {getFormErrorMessage("amount")}
                    </div>
                    <div className="component">
                      {pin <= 0 ? (
                        <Button
                          type="button"
                          label="Not Allowed"
                          disabled
                          size="large"
                        />
                      ) : (
                        <>
                          {isButtonDisabled == 0 ? (
                            <Button
                              type="submit"
                              label="Confirm"
                              size="large"
                            />
                          ) : (
                            <>
                              {isButtonDisabled == 1 ? (
                                <Button
                                  type="Button"
                                  label="Confirming..."
                                  size="large"
                                />
                              ) : (
                                <Button
                                  type="button"
                                  label="Confirmed"
                                  size="large"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="component">
                      <Link to="/forgetpassword">Forget Password?</Link>
                    </div>
                    <div className="component">
                      <p>
                        Don't have account? <Link to="/signup/1">Sign Up</Link>
                      </p>
                    </div> */}
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
