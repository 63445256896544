import React, { useEffect, useState } from "react";
import "./renderedhelp.scss";
import axios from "axios";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Card } from "primereact/card";
export const RenderedHelp = () => {
  const [data, setData] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("p2pemailxemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/renderedhelp",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.renderedhelp);
        // setActDown(resp.data.userData.actdown)
        // setHelpSecker(resp.data.hitIdRs)
        // setHelpAmt(resp.data.amt)
        // setButtonDisabled(0);
      } else if (resp.status === 203) {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: resp.data.message,
        // });
      }
    };
    fetchData();
  }, [actemail, url, apikey]);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="renderedhelp">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Help Rendered">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Helped</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.help_taker}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
