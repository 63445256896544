import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./edituser.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
export const EditUser = () => {
  let { userid } = useParams();
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(-1);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/admin/fetchdata",
        {
          userid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        formik.setFieldValue("email", resp.data.user.email);
        formik.setFieldValue("password", resp.data.user.password);
        formik.setFieldValue("whatsapp", resp.data.user.whatsapp);
        formik.setFieldValue("bep20", resp.data.user.bep20);
        formik.setFieldValue("mydown", resp.data.user.mydown);
        formik.setFieldValue("actdown", resp.data.user.actdown);
        formik.setFieldValue("isactive", resp.data.user.is_active);
      }
    };
    fetchData();
  },[]);
  const validationSchema = Yup.object({
    memberid: Yup.mixed().required("Its Required!!!"),
    email: Yup.mixed().required("Its Required!!!"),
    password: Yup.mixed().required("Its Required!!!"),
    whatsapp: Yup.mixed().required("Its Required!!!"),
    bep20: Yup.mixed().required("Its Required!!!"),
    mydown: Yup.mixed().required("Its Required!!!"),
    actdown: Yup.mixed().required("Its Required!!!"),
    isactive: Yup.mixed().required("Its Required!!!"),
  });
  const formik = useFormik({
    initialValues: {
      memberid: userid,
      email: "",
      password: "",
      whatsapp: "",
      bep20: "",
      mydown:"",
      actdown:"",
      isactive:""
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/admin/updateuser",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Activation successfull.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(0);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="edituser">
        <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6 left">
          <Card>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="component cust-bg">
                    <div>
                      <div>Memberid#</div>
                      <InputText
                        type="text"
                        name="memberid"
                        placeholder="Transection Hash"
                        onChange={formik.handleChange}
                        value={formik.values.memberid}
                      />
                      {getFormErrorMessage("memberid")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>Email#</div>
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>Password#</div>
                      <InputText
                        type="text"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {getFormErrorMessage("password")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>Whatsapp#</div>
                      <InputText
                        type="text"
                        name="whatsapp"
                        placeholder="WHATSAPP"
                        onChange={formik.handleChange}
                        value={formik.values.whatsapp}
                      />
                      {getFormErrorMessage("whatsapp")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>BEP20#</div>
                      <InputText
                        type="text"
                        name="bep20"
                        placeholder="BEP20"
                        onChange={formik.handleChange}
                        value={formik.values.bep20}
                      />
                      {getFormErrorMessage("bep20")}
                    </div>
                  </div>

                  <div className="component cust-bg">
                    <div>
                      <div>MYDOWN#</div>
                      <InputText
                        type="text"
                        name="mydown"
                        placeholder="My Down"
                        onChange={formik.handleChange}
                        value={formik.values.mydown}
                      />
                      {getFormErrorMessage("mydown")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>Active Down#</div>
                      <InputText
                        type="text"
                        name="actdown"
                        placeholder="Active Down"
                        onChange={formik.handleChange}
                        value={formik.values.actdown}
                      />
                      {getFormErrorMessage("actdown")}
                    </div>
                  </div>
                  <div className="component cust-bg">
                    <div>
                      <div>Active#</div>
                      <InputText
                        type="text"
                        name="isactive"
                        placeholder="Is Active"
                        onChange={formik.handleChange}
                        value={formik.values.isactive}
                      />
                      {getFormErrorMessage("isactive")}
                    </div>
                  </div>
                  {isButtonDisabled === -1 ? (
                    <Button type="submit" label="Submit" size="small" />
                  ) : (
                    <>
                      {isButtonDisabled === 1 ? (
                        <Button
                          type="button"
                          label="Submitting..."
                          size="small"
                        />
                      ) : (
                        <Button type="button" label="Submited" size="small" />
                      )}
                    </>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
      </div>
    </div>
  );
};
