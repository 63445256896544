import React, { useEffect, useState, useRef } from "react";
import "./activateother.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
export const ActivateOther = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const actemail = localStorage.getItem("p2pemailxemail");
  const [pin, setPin] = useState("0");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getpin",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setPin(resp.data.balance);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    email: Yup.mixed().required("Email Required"),
    clientemail: Yup.mixed().required("You cannot leave this field blank."),
  });
  const formik = useFormik({
    initialValues: {
      email: actemail,
      clientemail: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/otheractive",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Activation successfull.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(0);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="activateother">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6 left">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/tlogo.png" width="100" alt="" />
                    <h3>OTHER ACTIVATION</h3>
                    <div className="component">
                      <div>Available Pin</div>
                      <InputText
                        type="number"
                        name="avlpin"
                        placeholder="Available Pin"
                        variant="filled"
                        readOnly
                        value={pin}
                      />
                      {getFormErrorMessage("avlpin")}
                    </div>
                    <div className="component">
                      <div>Member Email</div>
                      <InputText
                        type="email"
                        name="clientemail"
                        placeholder="Member Email"
                        onChange={formik.handleChange}
                        value={formik.values.clientemail}
                      />
                      {getFormErrorMessage("clientemail")}
                    </div>
                    <div className="component">
                      {pin <= 0 ? (
                        <Button
                          type="button"
                          label="Not Allowed"
                          disabled
                          size="large"
                        />
                      ) : (
                        <>
                          {isButtonDisabled == 0 ? (
                            <Button
                              type="submit"
                              label="Confirm"
                              size="large"
                            />
                          ) : (
                            <>
                              {isButtonDisabled == 1 ? (
                                <Button
                                  type="button"
                                  label="Confirming..."
                                  size="large"
                                />
                              ) : (
                                <Button
                                  type="button"
                                  label="Confirmed"
                                  size="large"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
