import React, { useEffect, useState, useRef } from "react";
import "./upgrade.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import Countdown from "react-countdown";
import { Divider } from "primereact/divider";
export const Upgrade = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("p2pemailxemail");
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [allowHelp, setAllowHelp] = useState(0);
  const [level, setLevel] = useState(0);
  const [amount, setAmount] = useState(0);
  const [lockedBy, setLockedBy] = useState("");
  const [lockTime, setLockTime] = useState("0000-00-00 00:00:00");
  const [rWhatsApp, setRWhatsApp] = useState("");
  const [rBep20, setRBep20] = useState("");
  const [rEmail, setREmail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/renderhelp",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setAllowHelp(resp.data.allowHelp);
        setLevel(resp.data.userData.act_level);
        setLockedBy(resp.data.lockedBy);
        setRWhatsApp(resp.data.hitIdRs.whatsapp);
        setRBep20(resp.data.hitIdRs.bep20);
        setREmail(resp.data.hitIdRs.email);
        setLockTime(resp.data.lockTime);
        setAmount(resp.data.amt);
        if (resp.data.lockedBy === actemail && resp.data.allowHelp === 0) {
          setButtonDisabled(1);
        } else {
          setButtonDisabled(2);
        }
      }
    };
    fetchData();
  }, [actemail, apikey, url]);
  const validationSchema = Yup.object({
    tranhash: Yup.mixed("Invalid Hash").required("Field can not be empty."),
  });
  const formik = useFormik({
    initialValues: {
      tranhash: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/renderhelpconf",
        {
          email: actemail,
          whatsapp: rWhatsApp,
          tranhash: values.tranhash,
          helping: rEmail,
          amt: amount,
          actlevel: level,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Level Upgraded. Please Refresh",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong! Try Correcting again.",
        });
        setButtonDisabled(0);
      }
    },
  });
  const lockTran = async () => {
    const resp = await axios.post(
      url + "/user/locktran",
      {
        email: actemail,
        forlevel: level,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setAllowHelp(0);
      setLockedBy(actemail);
      setLockTime(resp.data.lockTime);
      setButtonDisabled(1);
    }
  };
  const setLoad = () => {
    setAllowHelp(1);
    setButtonDisabled(0);
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="upgrade">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card>
            <div className="content">
              <div className="title">Render Help</div>
              <Divider />
              <div className="helpingstatus">
                <div>Help Status</div>
                <div>{allowHelp === 1 ? "OPEN" : "IS LOCKED"}</div>
              </div>
              <div className="lockedby">
                <div>Locked By</div>
                <div>{allowHelp === 1 ? "NA" : lockedBy}</div>
              </div>
              <div className="count">
                <div>Count:</div>
                <div>
                  {allowHelp === 1 ? (
                    "00:00"
                  ) : (
                    <Countdown date={lockTime} onComplete={() => setLoad()} />
                  )}
                </div>
              </div>
              {allowHelp === 1 ? (
                <Button label="Lock Transaction" onClick={() => lockTran()} />
              ) : (
                <Button label="Lock Transaction" disabled />
              )}
            </div>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <Divider />
                  <div className="cont">
                    <div>WhatsApp</div>
                    <div>{rWhatsApp}</div>
                  </div>
                  <div className="cont">
                    <div>BEP20</div>
                    <div className="bep">
                      <span>{rBep20}</span>
                      <span className="pi pi-copy copy"></span>
                    </div>
                  </div>
                  <div className="cont">
                    <div>Amount</div>
                    <div>${amount}</div>
                  </div>
                  <InputText
                    type="text"
                    name="tranhash"
                    value={formik.values.tranhash}
                    onChange={formik.handleChange}
                    placeholder="TranHash#"
                  />
                  {getFormErrorMessage("tranhash")}
                  {isButtonDisabled === 0 ? (
                    <Button type="button" label="Lock Transaction" disabled />
                  ) : (
                    <>
                      {isButtonDisabled === 1 ? (
                        <Button type="submit" label="Submit" />
                      ) : (
                        <Button type="button" label="Not Valid" disabled />
                      )}
                    </>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
      </div>
    </div>
  );
};
