import React, { useEffect, useState } from "react";
import "./myaccount.scss";
import { Card } from "primereact/card";
import axios from "axios";
export const MyAccount = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("p2pemailxemail");
  const [levelData, setLevelData] = useState();
  const [userstatus, setUserstatus] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/fetchaccdetails",
        {
          actemail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setLevelData(resp.data.level);
        setUserstatus(resp.data.userstatus);
      }
    };
    fetchData();
  }, [actemail, url, apikey]);
  if (!levelData) return false;
  var rows = [];
  for (let i = 0; i <= 8; i++) {
    if (i < levelData.length * 1) {
      var fid = levelData[i].fid;
      rows.push({ fid });
    } else {
      rows.push({ fid: 0 });
    }
  }
  return (
    <div className="myaccount">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6 left">
          <Card title="Level Details" className="cust-bg">
            <div className="container">
              <div className="cont">
                <span>WORKING</span>
                <span>{userstatus===1?
                <>Active</>
                :
                <>Not Active</>
                }</span>
              </div>
              {rows.map((row, index) => (
                <div key={index} className="cont">
                  <span>LEVEL {index * 1 + 1}</span>
                  <span>{row.fid}</span>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
