import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./style.scss";
import { DarkModeContext } from "./context/darkModeContext";
import React, { useContext } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"

import { HomeAppbar } from "./components/homecomponent/HomeAppbar";
import { HomeFooter } from "./components/homecomponent/HomeFooter";
import { Home } from "./pages/home/Home";
import { SignUp } from "./pages/home/SignUp";
import { Login } from "./pages/home/Login";
import { UserDashboard } from "./pages/user/dashboard/UserDashboard";
import { Userappbar } from "./components/usercomponent/Userappbar";
import { UserFooter } from "./components/usercomponent/UserFooter";
import { MyAccount } from "./pages/user/myaccount/MyAccount";
import { MailVerify } from "./pages/user/mailverify/MailVerify";
import { ActivateSelf } from "./pages/user/activateself/ActivateSelf";
import { TransferPin } from "./pages/user/transferpin/TransferPin";
import { ActivateOther } from "./pages/user/activateother/ActivateOther";
import { Upgrade } from "./pages/user/upgrade/Upgrade";
import { PendingHelp } from "./pages/user/pendinghelp/PendingHelp";
import { MyDown } from "./pages/user/mydown/MyDown";
import { ForgetPassword } from "./pages/home/forgetpassword/ForgetPassword";
import { AdDashBoard } from "./pages/admin/addashboard/AdDashBoard";
import { AdminLogon } from "./pages/home/AdminLogon";
import { AdminAppBar } from "./components/admincomponent/AdminAppBar";
import { UserList } from "./pages/admin/users/UserList";
import { RenderedHelp } from "./pages/user/renderedhelp/RenderedHelp";
import { ReceivedHelp } from "./pages/user/receivedhelp/ReceivedHelp";
import { Profile } from "./pages/user/profile/Profile";
import { HelpStatus } from "./pages/admin/helpstatus/HelpStatus";
import { EditUser } from "./pages/admin/edituser/EditUser";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppbar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Userappbar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminAppBar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/login", element: <Login /> },
        { path: "/adminlogin", element: <AdminLogon /> },
        { path: "/signup/:spid", element: <SignUp /> },
        { path: "/forgetpassword", element: <ForgetPassword /> },
      ]
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <UserDashboard /> },
        { path: "/user/myaccount", element: <MyAccount /> },
        { path: "/user/mailverify", element: <MailVerify /> },
        { path: "/user/activateself", element: <ActivateSelf /> },
        { path: "/user/activateother", element: <ActivateOther /> },
        { path: "/user/transferpin", element: <TransferPin /> },
        { path: "/user/renderhelp", element: <Upgrade /> },     
        { path: "/user/pendinghelp", element: <PendingHelp /> },
        { path: "/user/mydownline", element: <MyDown /> },
        { path: "/user/renderedhelp", element: <RenderedHelp /> },
        { path: "/user/receivedhelp", element: <ReceivedHelp /> },
        { path: "/user/profile", element: <Profile /> },
    ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/", element: <AdDashBoard /> },
        { path: "/admin/allusers", element: <UserList /> },
        { path: "/admin/helpstatus", element: <HelpStatus /> },
        { path: "/admin/edituser/:userid", element: <EditUser /> },      
    ],
    },
  ])
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
