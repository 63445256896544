import React, { useContext } from "react";
import { Menubar } from "primereact/menubar";
import { Router } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { Button } from "primereact/button";
// import "./userappbar.scss";
import { useNavigate } from "react-router-dom";
export const AdminAppBar = () => {
    const items = [
        {
          label:"Dashboard",
          icon: "pi pi-home",
          url: "/admin/",
        },
          {
            label: "User",
            icon: "pi pi-user",
            items: [
              {
                label: "All Users",
                icon: "pi pi-face-smile",
                url: "/admin/allusers",
              },
              
            //   {
            //     label: "Profile",
            //     icon: "pi pi-user-edit",
            //     url: "#",
            //   },
            //   {
            //     label: "Render Help",
            //     icon: "pi pi-user-edit",
            //     url: "/user/renderhelp",
            //   },
            //   {
            //     label: "Activate Other",
            //     icon: "pi pi-user-plus",
            //     url: "/user/activateother",
            //   },
            ],
          },
          {
            label: "Transection",
            icon: "pi pi-server",
            items: [
              {
                label: "Transfer Pin",
                icon: "pi pi-bolt",
                url: "#",
              },
              {
                label: "Help Status",
                icon: "pi pi-bolt",
                url: "/admin/helpstatus",
              },
            ],
          },
        ];
        const Navigate = useNavigate();
        const { toggle, darkMode } = useContext(DarkModeContext);
        const start = (
          <div className="flex align-items-center gap-1">
            <img alt="logo" src="/images/tlogo.png" height="40" className="mr-2" />
            <span className="headlogo">P2P</span>
          </div>
        );
        const end = (
          <div className="flex align-items-center gap-1">
            {darkMode ? (
              <span
                className="pi pi-moon"
                onClick={toggle}
                style={{ cursor: "pointer", marginRight: 20 }}
              />
            ) : (
              <span
                className="pi pi-sun"
                onClick={toggle}
                style={{ cursor: "pointer", marginRight: 20 }}
              />
            )}
            <Button
              icon="pi pi-power-off"
              size="small"
              severity="danger"
              onClick={() => logout()}
            />
          </div>
        );
        const logout = () => {
          localStorage.clear();
          Navigate("/");
        };
  return (
    <div>
      <div className="card userappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  )
}
