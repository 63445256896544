import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";
import * as Yup from "yup";
import "./mailverify.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
export const MailVerify = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const actuser = localStorage.getItem("p2pemailxemail");
  const [otpbuttonstate, setOtpButtonstate] = useState(0);
  const [otpsubmit, setOtpSubmit] = useState(0);
  const Navigate = useNavigate();
  const toast = useRef(null);
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    bep20: Yup.mixed().required("Empty Field"),
    otp: Yup.number().required("Invalid Password."),
  });
  const formik = useFormik({
    initialValues: {
      email: actuser,
      bep20:"",
      otp: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setOtpSubmit(1);
      const resp = await axios.post(
        url + "/user/mailauthcomplete",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setOtpSubmit(2);
        setVisible(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Credential! Try Correcting again.",
        });
      }
    },
  });
  const sendotp = async () => {
    setOtpButtonstate(1);
    const resp = await axios.post(
      url + "/user/mailauthinit",
      { actuser },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      setOtpButtonstate(2);
    } else {
      setOtpButtonstate(0);
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => Navigate("/user")}
        autoFocus
      />
    </div>
  );
  return (
    <div className="mailverify">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/tlogo.png" width="100" alt="" />
                    <h3>Email Verification</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        readOnly
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="bep20"
                        placeholder="BEP20 Wallet Address"
                        onChange={formik.handleChange}
                        value={formik.values.bep20}
                      />
                      {getFormErrorMessage("bep20")}
                    </div>
                    <div className="component">
                      {otpbuttonstate === 0 ? (
                        <Button
                          type="button"
                          severity="help"
                          onClick={() => sendotp()}
                          label="Get OTP"
                        />
                      ) : (
                        <>
                          {otpbuttonstate === 1 ? (
                            <Button
                              type="button"
                              severity="help"
                              label="Fetching OTP..."
                            />
                          ) : (
                            <Button
                              type="button"
                              severity="help"
                              label="OTP Sent to Mail."
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="otp"
                        placeholder="OTP"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                      />
                      {getFormErrorMessage("otp")}
                    </div>
                    <div className="component">
                      {otpsubmit === 0 ? (
                        <Button
                          type="submit"
                          severity="info"
                          label="Submit"
                          size="large"
                        />
                      ) : (
                        <>
                          {otpsubmit === 1 ? (
                            <Button
                              type="button"
                              severity="info"
                              label="Submittting"
                              size="large"
                            />
                          ) : (
                            <Button
                              type="button"
                              severity="info"
                              label="Submitted"
                              size="large"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        header="Success"
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">Congratulation Email Verified Successfully.</p>
        <p className="m-0">Enjoy Community!!!</p>
      </Dialog>
    </div>
  );
};
