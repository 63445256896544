import React, { useEffect, useRef, useState } from "react";
import "./pendinghelp.scss";
import { Card } from "primereact/card";
import axios from "axios";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const PendingHelp = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [butt, setButt] = useState(0)
  const [butReject, setButReject] = useState(0)
  const [data, setData] = useState({});
  const email = localStorage.getItem("p2pemailxemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getpendinglist",
        {
          email,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.pendinglist);
      }
    };
    fetchData();
    // if (!initialized.current) {
    //   initialized.current = true
    //   fetchData();
    // }
  }, []);
  const approve = async (helper, level) => {
    setButt(1)
    const resp = await axios.post(
      url + "/user/approvehelp",
      {
        email,
        helper,
        level,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Activation successfull.",
      });
      window.location.reload(true)
    }
  };
  const reject = async (helper, level) => {
    setButReject(1)
    const resp = await axios.post(
      url + "/user/rejectehelp",
      {
        email,
        helper,
        level,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Activation successfull.",
      });
      window.location.reload(true)
    }
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="pendinghelp">
    <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <Card title="Help Seek Requests" className="cust-bg">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Giver</TableCell>
                    <TableCell align="center">Level</TableCell>
                    <TableCell align="center">Tran Hash</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Approve</TableCell>
                    <TableCell align="center">Reject</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.help_giver}</TableCell>
                      <TableCell align="center">{row.for_level}</TableCell>
                      <TableCell align="center">{row.tran_hash}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">
                        {butt==0?
                        <Button
                        label="Approve"
                        onClick={() => approve(row.help_giver, row.for_level)}
                      />
                        :
                        <Button
                          label="Refresh Page"
                          disabled
                        />
                        }
                        
                      </TableCell>
                      <TableCell align="center">
                        {butReject==0?
                        <Button label="Reject" onClick={()=>reject(row.help_giver, row.for_level)} />
                        :
                        <Button label="WORKING" disabled />
                        }
                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
